export const contact = () => {
  const contactsItem = document.querySelectorAll(".contacts-item");
  const maps = document.querySelectorAll(".maps");
  const mapContact = document.querySelector(".contact_item.form");
  const mapButtonsDirection = document.querySelectorAll(
    ".map_buttom_direction"
  );
  const mapButtonsHide = document.querySelectorAll(".map_buttom_hides");

  let activeContact = null;

  if (window.outerWidth > 1024) {
    contactsItem.forEach((e) => {
      e.addEventListener("click", () => {
        let elData = e.dataset.id;

        if (activeContact === elData) {
          e.classList.remove("active");
          maps.forEach((map) => {
            if (map.dataset.id === elData) {
              map.classList.remove("active");
              map.style.display = "none";
            }
          });
          mapContact.classList.remove("show-map");
          activeContact = null;
          mapButtonsDirection.forEach((button) => {
            if (button.dataset.id === elData) {
              button.classList.remove("hide");
            }
          });
          mapButtonsHide.forEach((button) => {
            if (button.dataset.id === elData) {
              button.classList.add("hide");
            }
          });
        } else {
          maps.forEach((map) => {
            map.classList.remove("active");
            map.style.display = "none";
          });
          const prevActiveContact = activeContact;

          activeContact = elData;
          e.classList.add("active");
          maps.forEach((map) => {
            if (map.dataset.id === elData) {
              map.classList.add("active");
              map.style.display = "block";
            }
          });

          mapContact.classList.add("show-map");
          mapButtonsDirection.forEach((button) => {
            if (button.dataset.id === elData) {
              button.classList.add("hide");
            }
          });
          mapButtonsHide.forEach((button) => {
            if (button.dataset.id === elData) {
              button.classList.remove("hide");
            }
          });
          if (prevActiveContact) {
            mapButtonsDirection.forEach((button) => {
              if (button.dataset.id === prevActiveContact) {
                button.classList.remove("hide");
              }
            });
            mapButtonsHide.forEach((button) => {
              if (button.dataset.id === prevActiveContact) {
                button.classList.add("hide");
              }
            });
          }
        }
      });
    });
  }
  const footerListItems = document.querySelectorAll(
    ".footer_list_mob.footer__list-title-item.contacts-item"
  );
  footerListItems.forEach((item) => {
    item.addEventListener("mouseout", () => {});
  });
};
