export const number = () => {
  // $(window).on("scroll", function () {
  //   $(".animate-number").each(function () {
  //     if (isScrolledIntoView(this) && !$(this).hasClass("show")) {
  //       animateNumber(this);
  //       $(this).addClass("show");
  //     }
  //   });
  // });
  // function isScrolledIntoView(elem) {
  //   var docViewTop = $(window).scrollTop();
  //   var docViewBottom = docViewTop + $(window).height();
  //   var elemTop = $(elem).offset().top;
  //   var elemBottom = elemTop + $(elem).height();
  //   return elemBottom <= docViewBottom && elemTop >= docViewTop;
  // }
  // function animateNumber(elem) {
  //   var numberElement = $(elem).find("[data-number]");
  //   var targetValue = parseInt(numberElement.data("number"), 10);
  //   $({
  //     Counter: 0,
  //   }).animate(
  //     {
  //       Counter: targetValue,
  //     },
  //     {
  //       duration: 3000,
  //       easing: "swing",
  //       step: function () {
  //         numberElement.text(Math.ceil(this.Counter));
  //       },
  //     }
  //   );
  // }
};
