export const parallax = () => {
  var image = document.getElementsByClassName("thumbnail");
  new simpleParallax(image, {
    overflow: true,
    delay: 0.6,
    transition: "cubic-bezier(0,0,0,1)",
  });
  var image = document.getElementsByClassName("thumbnails");
  new simpleParallax(image, {
    overflow: true,
    delay: 0.6,
    transition: "cubic-bezier(0,0,0,1)",
  });
  // var image = document.getElementById("thumbnail_team");
  // new simpleParallax(image, {
  //   scale: 1.5,
  //   overflow: true,
  //   orientation: "down",
  // });

  var images = document.getElementsByClassName("thumbnailss");
  new simpleParallax(images, {
    overflow: true,
    scale: 2.0,
  });
  var image = document.getElementsByClassName("thumbnailis");
  new simpleParallax(image, {
    overflow: true,
  });
};
