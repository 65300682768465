export const blog = () => {
  document.addEventListener("DOMContentLoaded", function () {
    var categoryFilters = document.querySelectorAll(".category-filter");
    var typeFilters = document.querySelectorAll(".type-filter");
    var allPosts = document.querySelectorAll(".post");
    var body = document.querySelector("body");

    categoryFilters.forEach(function (filter) {
      filter.addEventListener("click", function () {
        // toggleFilter(this);
        filterPosts();
      });
    });

    typeFilters.forEach(function (filter) {
      filter.addEventListener("click", function () {
        // toggleFilter(this);
        filterPosts();
      });
    });

    body.addEventListener("click", function (event) {
      if (
        !event.target.classList.contains("category-filter") &&
        !event.target.classList.contains("type-filter")
      ) {
        clearActiveFilters();
      }
    });

    $(document).ready(function () {
      $(".category-filter").click(function () {
        $(".filter-group").removeClass("active");
        $(this).closest(".filter-group").addClass("active");
        $(this).toggleClass("active");
      });
      $(".type-filter").click(function () {
        $(".filter-group").removeClass("active");
        $(this).closest(".filter-group").addClass("active");
        $(this).toggleClass("active");
      });
    });

    // function toggleFilter(filter) {
    //   filter.classList.toggle("active");
    // }

    function filterPosts() {
      allPosts.forEach(function (post) {
        var postCategories = JSON.parse(post.dataset.category);
        var postTypes = JSON.parse(post.dataset.type);
        var activeCategoryFilters = Array.from(
          document.querySelectorAll(".category-filter.active")
        );
        var activeTypeFilters = Array.from(
          document.querySelectorAll(".type-filter.active")
        );
        var showPost = true;

        if (activeCategoryFilters.length > 0 || activeTypeFilters.length > 0) {
          var showPost = false;

          if (activeCategoryFilters.length > 0) {
            showPost = checkPostCategories(
              postCategories,
              activeCategoryFilters
            );
          }

          if (activeTypeFilters.length > 0) {
            showPost = checkPostTypes(postTypes, activeTypeFilters);
          }
        }
      });
    }

    function checkPostCategories(postCategories, activeCategoryFilters) {
      var selectedCategories = activeCategoryFilters.map(function (filter) {
        return parseInt(filter.dataset.filterValue);
      });

      return selectedCategories.some(function (category) {
        return postCategories.includes(category);
      });
    }

    function checkPostTypes(postTypes, activeTypeFilters) {
      var selectedTypes = activeTypeFilters.map(function (filter) {
        return parseInt(filter.dataset.filterValue);
      });

      return selectedTypes.some(function (type) {
        return postTypes.includes(type);
      });
    }

    function clearActiveFilters() {
      categoryFilters.forEach(function (filter) {
        filter.classList.remove("active");
      });

      typeFilters.forEach(function (filter) {
        filter.classList.remove("active");
      });

      filterPosts();
    }
  });
};
