// Визначте функцію для обробки плавного скролінгу
export const scroll = () => {
  function smoothScroll(target) {
    const element = document.querySelector(target);
    window.scrollTo({
      top: element.offsetTop,
      behavior: "smooth",
    });
  }
  const links = document.querySelectorAll('a[href^="#"]');

  links.forEach((link) => {
    link.addEventListener("click", (event) => {
      event.preventDefault();
      const target = link.getAttribute("href");
      smoothScroll(target);
    });
  });
};
