export const popup = () => {
  const buttons = document.querySelectorAll(".button_link");
  const popup = document.querySelector(".popup");
  const overlay = document.querySelector(".overlay");
  const closeButton = document.querySelector(".close-popup");
  const questionTitlesContainer = document.querySelector(
    ".question_titles_container"
  );
  const contactForm = document.querySelector(".contact_shortcode");
  const contactFormThanks = document.querySelector(".contact-form-thankss");
  const background = document.querySelector(".background");
  let formWrapper;

  buttons.forEach(function (button) {
    button.addEventListener("click", function (event) {
      event.preventDefault();
      const questionContent = button.closest(".questions_content");
      const questionTitle = questionContent.querySelector(".question_titles");

      questionTitlesContainer.innerHTML = "";
      questionTitlesContainer.appendChild(questionTitle.cloneNode(true));
      document.getElementById("question_title").value =
        questionTitle.textContent;

      popup.classList.add("active");
      background.classList.add("active");
      formWrapper = document.querySelector("#kontakt");
      formWrapper
        .querySelector(".contact-form-wrapper")
        .classList.remove("hide");
    });
  });

  closeButton.addEventListener("click", function () {
    closePopup();
  });

  // overlay.addEventListener("click", function (event) {
  //   if (event.target === overlay) {
  //     closePopup();
  //   }
  // });

  document.addEventListener("keydown", function (event) {
    if (event.key === "Escape") {
      closePopup();
    }
  });

  function closePopup() {
    popup.classList.remove("active");
    contactFormThanks.style.display = "none";
    contactForm.style.display = "block";
    background.classList.remove("active");
    if (formWrapper) {
      formWrapper.querySelector(".contact-form-wrapper").classList.add("hide");
    }
  }

  // Отримуємо форму для відправки
  const contactForm7 = document.querySelector(".wpcf7-form");

  // Слухаємо подію "wpcf7mailsent" (відправлення форми)
  contactForm7.addEventListener("wpcf7mailsent", function () {
    contactForm.style.display = "none";
    contactFormThanks.style.display = "block";

    setTimeout(function () {
      closePopup();
    }, 4000); // Закриття через 3 секунди (3000 мілісекунд)
  });
};
