export const blogactive = () => {
  $(document).ready(function () {
    let isFilterOpen = false; // Змінна для зберігання стану елементів

    // Додаємо обробник події для кнопки з класом "open-filter-box"
    $(document).on("click", ".open-filter-box", function () {
      // Перевіряємо стан елементів
      if (isFilterOpen) {
        // Якщо елементи вже відкриті, закриваємо їх
        $(".filters").removeClass("active");
        $(".filter-group").removeClass("active");
      } else {
        // Інакше, відкриваємо їх
        $(".filters").addClass("active");
        $(".filter-group").addClass("active");
      }
      // Змінюємо стан елементів
      isFilterOpen = !isFilterOpen;
    });

    // Додаємо обробник подій для елементів "a.filter-option" та "#clear-filter-pc" для закриття фільтра
    $(document).on("click", "a.filter-option, #clear-filter-pc, .pagination a", function (e) {
      e.preventDefault();
      // Закриваємо фільтр
      $(".filters").removeClass("active");
      $(".filter-group").removeClass("active");
      // Змінюємо стан елементів
      isFilterOpen = false;

      var url = $(e.currentTarget).attr("href");
      $("#blog").addClass("loading");
      $.get(url, function (data) {
        $("#blog").html($(data).find("#blog").html());
        $("#blog").removeClass("loading");
        history.pushState(null, "", url);
      });
    });
  });
};
