export const modals = () => {
  const forms = document.querySelectorAll(".wpcf7");
  const header = document.querySelector("#header");
  forms.forEach((form) => {
    let formWrapper = form.closest("#kontakt");
    form.addEventListener(
      "wpcf7mailsent",
      function (event) {
        let wrapper = form.closest(".contact-form-wrapper");
        let thanks = form
          .closest(".contact_forms")
          .querySelector(".contact-form-thanks");
        wrapper.classList.add("hide");
        thanks.classList.add("show");

        setTimeout(() => {
          wrapper.classList.remove("hide");
          thanks.classList.remove("show");
        }, 8000);
      },
      false
    );
  });

  const closeButton = document.querySelector(
    ".contact-form-thanks .close-button"
  );
  closeButton.addEventListener("click", function () {
    const thanks = this.closest(".contact-form-thanks");
    thanks.classList.remove("show");
    document.body.style.overflow = "auto";
  });
};
