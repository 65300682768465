export const filter = () => {
  const filterKeywords = document.querySelectorAll(".filter_keyword");
  const teamsList = document.querySelectorAll(".teams_list");

  filterKeywords.forEach((keyword) => {
    keyword.addEventListener("click", () => {
      const selectedKeyword = keyword.innerText.toLowerCase();
      filterItems(selectedKeyword);
    });
  });

  function filterItems(selectedKeyword) {
    teamsList.forEach((list) => {
      const listItem = list;
      const keyAttribute = list.getAttribute("data-key").toLowerCase();

      if (selectedKeyword === "all" || keyAttribute.includes(selectedKeyword)) {
        listItem.style.display = "block";
      } else {
        listItem.style.display = "none";
      }
    });
  }
  const teamKeys = document.querySelectorAll(".team_key");

  teamKeys.forEach((key, index) => {
    key.addEventListener("click", () => {
      
      teamKeys.forEach((item) => {
        item.classList.remove("active");
      });

      
      key.classList.add("active");

      const selectedKeyword = key
        .querySelector(".filter_keyword")
        .innerText.toLowerCase();
      filterItems(selectedKeyword);
    });

   
    if (index === 0) {
      key.classList.add("active");
    }
  });
};
