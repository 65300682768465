export const industries = () => {
  $(".industries-item-title-container").on("click", function () {
    $(this).toggleClass("active");
    $(this).parent(".industries-item").toggleClass("active");
    $(this)
      .siblings(".industries-item-content-container")
      .each(function () {
        $(this).toggleClass("hidden");
      });
    $(this)
      .closest(".industries-item")
      .siblings(".industries-item")
      .each(function () {
        $(this).find(".industries-item-title-container").removeClass("active");
        $(this).find(".industries-item-content-container").addClass("hidden");
      });
    $(this)
      .parent(".industries-item")
      .closest(".industries-item")
      .siblings(".industries-item")
      .each(function () {
        $(this).removeClass("active");
      });
  });
};
