export const join = () => {
  var tabs = document.querySelectorAll(".tabs_item li");
  var tabContents = document.querySelectorAll(".tab_content_items .tab_item");
  var questionTitles = document.querySelectorAll(".questions li h4");
  var allTab = document.querySelector('[data-tab="all"]');
  allTab.classList.add("active");
  tabContents.forEach(function (content) {
    content.classList.add("active");
  });
  tabs.forEach(function (tab, index) {
    tab.addEventListener("click", function () {
      tabs.forEach(function (tab) {
        tab.classList.remove("active");
      });

      this.classList.add("active");

      var selectedTab = this.getAttribute("data-tab");
      tabContents.forEach(function (content) {
        if (
          selectedTab === "all" ||
          content.getAttribute("data-tab") === selectedTab
        ) {
          content.classList.add("active");
        } else {
          content.classList.remove("active");
        }
      });
    });
  });

  questionTitles.forEach(function (title) {
    title.addEventListener("click", function () {
      var parentLi = this.parentNode;
      var questionContent = parentLi.querySelector(".question_content");

      if (parentLi.classList.contains("active")) {
        parentLi.classList.remove("active");
        questionContent.style.maxHeight = null;
      } else {
        var activeQuestions = document.querySelectorAll(
          ".questions_content.active"
        );
        activeQuestions.forEach(function (activeQuestion) {
          var activeQuestionContent =
            activeQuestion.querySelector(".question_content");
          activeQuestion.classList.remove("active");
          activeQuestionContent.style.maxHeight = null;
        });

        parentLi.classList.add("active");
        questionContent.style.maxHeight = questionContent.scrollHeight + "px";

        var offsetTop =
          parentLi.getBoundingClientRect().top + window.pageYOffset - 104;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    });
  });
};
