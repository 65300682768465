export const baneru = () => {
  // var swiper = new Swiper(".myswiperf", {
  //   slidesPerView: 3,
  //   spaceBetween: 30,
  //   navigation: {
  //     nextEl: ".swiper-buttons-next",
  //     prevEl: ".swiper-buttons-prev",
  //   },
  //   // autoplay: {
  //   //   delay: 3000,
  //   //   disableOnInteraction: false,
  //   // },
  // });
};
