export const team = () => {
  var images = Array.from(
    document.querySelectorAll(".flower_img img, .line_img img")
  );

  function revealImages() {
    images.forEach(function (image, index) {
      setTimeout(function () {
        image.style.opacity = 1;
        image.style.transform = "translateY(0)";
      }, (images.length - index) * 500); // Затримка між появою кожної картинки (у мілісекундах)
    });
  }

  revealImages();
};
