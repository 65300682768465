export const fille = () => {
  const fileInput = document.getElementById("file-input");
  const fileListContainer = document.getElementById("file-list-container");

  const fileButton = document.querySelector(".file-button");
  const placeholder = document.querySelector(".placeholder");

  fileInput?.addEventListener("change", function () {
    const files = Array.from(fileInput.files);
    files.forEach(function (file) {
      const fileName = file.name;

      const fileItem = document.createElement("div");
      fileItem.classList.add("file-item");

      const fileLabel = document.createElement("span");
      fileLabel.textContent = fileName;

      const fileRemove = document.createElement("span");
      fileRemove.classList.add("file-remove");
      fileRemove.textContent = "X";
      fileRemove.addEventListener("click", function () {
        removeFile(fileItem);
      });

      fileItem.appendChild(fileLabel);
      fileItem.appendChild(fileRemove);

      fileListContainer.appendChild(fileItem);
    });

    fileButton.textContent = "Uploads";
    placeholder.style.display = "none";
  });

  function removeFile(fileItem) {
    fileItem.remove();
    if (fileListContainer.children.length === 0) {
      fileButton.textContent = "Uploads";
      placeholder.style.display = "block";
    }
  }
};
