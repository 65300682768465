export const slider = () => {
  var swiper = new Swiper(".swiper-container", {
    // centeredSlides: "true",
    slidesPerView: "auto",
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
  });
};
