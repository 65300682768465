import { burger } from "./modules/header/burger.js";
import { modals } from "./modules/modals.js";
// import { menu } from "./modules/menu.js";
import { tabs } from "./modules/tabs.js";
import { team } from "./modules/team.js";
import { filter } from "./modules/filter.js";
import { industries } from "./modules/industries.js";
import { join } from "./modules/join.js";
import { slider } from "./modules/slider.js";
import { popup } from "./modules/popup.js";
import { services } from "./modules/services.js";
import { fille } from "./modules/fille.js";
import { number } from "./modules/number.js";
// import { rest } from "./modules/work/rest.js";
import { single } from "./modules/single.js";
import { baneru } from "./modules/baneru.js";
import { blogactive } from "./modules/blogactive.js";
import { blog } from "./modules/blog.js";
// import { about } from "./modules/about.js";
// import { blogmob } from "./modules/blogmob.js";
import { button } from "./modules/button.js";
import { parallax } from "./modules/parallax.js";
import { scroll } from "./modules/scroll.js";
import { contact } from "./modules/contact.js";
import "./modules/header/sticky.js";

document.addEventListener("DOMContentLoaded", function (event) {
  // menu();
  burger();
  contact();
  filter();
  number();
  baneru();
  slider();
  modals();
  tabs();
  blogactive();
  blog();
  // blogmob();
  fille();
  scroll();
  parallax();
  team();
  // rest();
  industries();
  join();

  button();
  popup();
  single();
  // services();
});
